import React, { useEffect, useMemo, useState } from "react";

import { SubscriptionIntervals, SubscriptionPlans } from "@prisma/client";
import clsx from "clsx";
import Link from "next/link";
import { useSession } from "next-auth/react";

import Button from "@musicfy/components/Button";
import Card from "@musicfy/components/Card";
import Icon from "@musicfy/components/Icon";
import Switcher from "@musicfy/components/Switcher";
import { SubscriptionPlanInfo } from "@musicfy/contants/Subscriptions";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { useRequireAuthContext } from "@musicfy/libs/RequireAuthProvider";

import FAQ from "./FAQ";
import FeaturesBreakdown from "./FeaturesBreakdown";
import PlanCard from "./PlanCard";
import FullScreenWrapper from "../shared/FullScreenWrapper";

const Pricing = (): JSX.Element => {
  const { status, data } = useSession();

  const { setActivePaddleScreen } = usePaddleContext();
  const { showAuthModal } = useRequireAuthContext();

  const [interval, setInterval] = useState<SubscriptionIntervals>(
    SubscriptionIntervals.year
  );

  useEffect(() => {
    if (status === "unauthenticated") {
      showAuthModal();
    }
  }, [showAuthModal, status]);

  const intervalOptions = [
    {
      label: "Monthly",
      value: SubscriptionIntervals.month,
      "aria-label": "Monthly",
    },
    {
      label: "Yearly",
      value: SubscriptionIntervals.year,
      "aria-label": "Yearly",
    },
  ];

  const availableSubscriptionPlans = useMemo(() => {
    return Object.entries(SubscriptionPlanInfo).filter(([key]) => {
      if (key === SubscriptionPlans.label && !data?.user.isAdmin) {
        return false;
      }

      return true;
    });
  }, [data?.user.isAdmin]);

  return (
    <FullScreenWrapper
      style="gradient"
      open
      onClose={() => setActivePaddleScreen(null)}
    >
      <div className="mx-auto w-full max-w-[1380px] px-5 pt-5 pb-9">
        <div className="flex flex-col gap-6">
          <Link
            href="/"
            className="cursor-pointer xl:relative xl:top-0 xl:left-0 absolute top-12 left-4 flex items-center gap-2 xl:mt-4"
          >
            <Icon name="logo" className="w-6 aspect-[1.1]" />
            <Icon name="logo-text" />
          </Link>

          <div className="flex flex-col lg:flex-row items-center gap-4 mt-2">
            <div className="flex flex-1 cursor-default">
              <Card>
                <div className="px-1 flex items-center gap-2">
                  <Icon name="percent" />
                  <span className="capitalize">Save 20% On Yearly Plans</span>
                </div>
              </Card>
            </div>

            <div className="flex-1 flex items-center justify-center">
              <Switcher
                labelClassName="!px-12 !font-light"
                className="!w-fit"
                value={interval}
                setValue={setInterval}
                options={intervalOptions}
              />
            </div>

            <div className="flex-1 lg:flex hidden justify-end">
              <Button
                variant="text"
                className="text-18"
                onClick={() => {
                  document
                    .querySelector("#features-breakdown")
                    ?.scrollIntoView({
                      behavior: "smooth",
                    });
                }}
              >
                View Features
              </Button>
            </div>
          </div>

          <div
            className={clsx("grid grid-cols-1 gap-4 lg:gap-6 lg:mt-4", {
              "xl:grid-cols-2": availableSubscriptionPlans.length === 4,
              "xl:grid-cols-3": availableSubscriptionPlans.length !== 4,
            })}
          >
            {availableSubscriptionPlans.map(([key, details]) => {
              return (
                <PlanCard interval={interval} details={details} key={key} />
              );
            })}
          </div>

          <div className="mt-6" id="features-breakdown">
            <FeaturesBreakdown />
          </div>

          <div className="mt-6">
            <FAQ />
          </div>
        </div>
      </div>
    </FullScreenWrapper>
  );
};

export default Pricing;
